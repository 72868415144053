<template>
  <div :class="{ 'field-grow': grow }" class="description-field">
    <div
      v-if="!showTextArea"
      @click="focusOnTextArea($event)"
      class="parsed-description nice-Vscroll"
      v-html="$service.formattedHtmlTextMessage(description) || 'Observação 💬'"
    />

    <b-form-textarea
      v-else
      @input="updateModelValue()"
      @blur="checkDescriptionLimit($event)"
      class="borderless-textarea"
      ref="descriptionTextArea"
      placeholder="Observação"
      max-rows="2"
      rows="2"
      v-model="description"
    />
  </div>
</template>

<script>
import { BFormTextarea } from "bootstrap-vue";

export default {
  props: {
    value: {
      required: true,
    },

    grow: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    BFormTextarea,
  },

  data() {
    return {
      showTextArea: false,

      description: this.value,
    };
  },

  watch: {
    value() {
      this.description = this.value;
    },
  },

  methods: {
    updateModelValue() {
      this.$emit("input", this.description);
    },

    focusOnTextArea($event) {
      if ($event?.target?.tagName?.toLowerCase() === "a") return;

      this.$emit("selected");

      this.showTextArea = true;

      this.$nextTick(() => {
        this.$refs.descriptionTextArea.focus();
      });
    },

    checkDescriptionLimit() {
      if (this.description?.length > 10000) {
        this.$bvToast.toast(
          "O limite de caracteres do campo de descrição é de 10.000 caracteres.",
          {
            title: `Limite da Descrição alcançado!`,
            autoHideDelay: 2500,
            variant: "warning",
            toaster: "b-toaster-top-left",
            solid: true,
          }
        );

        this.description = this.description.substring(0, 10000);

        this.$emit("input", this.description);
      }

      this.$emit("blur");

      this.showTextArea = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.field-grow {
  flex-grow: 1;
  height: 100%;

  .parsed-description,
  .borderless-textarea {
    height: 100% !important;
  }
}

.description-field {
  width: 100%;
  border-radius: 6px;
  border: solid 1px #c4ccd8;

  .parsed-description {
    word-break: break-word;
    letter-spacing: 0.01em;
    width: 100%;
    height: 75px;
    padding: 12px;
    border-radius: 6px;
  }

  .borderless-textarea {
    letter-spacing: 0.01em;
    height: 75px;
    border-radius: 6px;
    border: none;
  }
}
</style>
