<template>
  <div v-if="!hasRequest" @click="handleClick($event)" class="modal-carousel">
    <div class="close-button-holder">
      <feather-icon
        @click.stop="downloadImage()"
        icon="DownloadIcon"
        style="cursor: pointer"
        size="26"
        color="#ffffff"
      />

      <i @click="closeModalCarousel()" class="icon-close-circle-outline" />
    </div>

    <div id="backdrop" class="image-box">
      <i
        @click="changeInedex('previous')"
        :class="hasPreviousImage ? 'active-arrow icon-keyboard-arrow-left' : 'disabled-arrow'"
        id="left-arrow"
      />

      <img :src="images[currentIndex]" alt="carousel" id="central-image" />

      <i
        @click="changeInedex('next')"
        :class="hasNextImage ? 'active-arrow icon-keyboard-arrow-right' : 'disabled-arrow'"
        id="right-arrow"
      />
    </div>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";

export default {
  components: {
    BSpinner,
  },

  data() {
    return {
      currentIndex: null,
      hasNextImage: false,
      hasPreviousImage: false,
      hasRequest: true,
    };
  },

  computed: {
    images() {
      return this.$store.getters["modalCarousel/getCarouselImages"];
    },

    specificImage() {
      return this.$store.getters["modalCarousel/getSpecificImage"];
    },
  },

  mounted() {
    this.getCurrentImage();
  },

  methods: {
    closeModalCarousel() {
      this.$store.dispatch("modalCarousel/changeShowCarousel", false);
    },

    getFileName(imageUrl) {
      return imageUrl.split("/").pop().split("?")[0] || "imagem.jpg";
    },

    handleClick(event) {
      const clickedElement = event.target.id;

      const nonBackdropElements = {
        "left-arrow": true,
        "right-arrow": true,
        "central-image": true,
        "image-download": true,
        spinner: true,
      };

      if (!nonBackdropElements[clickedElement]) {
        this.closeModalCarousel();
      }
    },

    getCurrentImage() {
      this.images.forEach((element, index) => {
        if (element == this.specificImage) {
          this.currentIndex = index;
        }
      });

      this.checkPreviousAndNext();

      this.hasRequest = false;
    },

    changeInedex(direction) {
      if (direction == "previous" && this.currentIndex != 0) {
        this.currentIndex--;
      }

      if (direction == "next" && this.currentIndex != this.images.length - 1) {
        this.currentIndex++;
      }

      this.checkPreviousAndNext();
    },

    checkPreviousAndNext() {
      if (this.currentIndex == 0) {
        this.hasPreviousImage = false;
      } else {
        this.hasPreviousImage = true;
      }

      if (this.currentIndex == this.images.length - 1) {
        this.hasNextImage = false;
      } else {
        this.hasNextImage = true;
      }
    },

    async downloadImage() {
      const imageUrl = this.images[this.currentIndex];

      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = this.getFileName(imageUrl);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(blobUrl); // Limpa a memória
      } catch (error) {
        console.error("Erro ao baixar a imagem:", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  height: 100dvh;
  width: 100dvw;
  z-index: 999999;
  background: #000000d7;
  overflow: hidden;
  user-select: none;

  .close-button-holder {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 15px;
    padding: 2rem 3rem 0 0;

    i {
      font-size: 26px;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .image-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    height: 90%;
    max-height: 90%;
    min-height: 90%;
    width: 100%;
    padding: 0 2rem;
    margin-top: 1rem;
    color: #ffffff;

    img {
      height: auto;
      max-height: 80%;
      max-width: 80%;
    }

    .active-arrow {
      display: flex;
      align-items: center;
      height: 80%;
      width: 36px;
      border-radius: 6px;
      cursor: pointer;
    }

    .active-arrow:hover {
      background: #0000008a;
    }

    .active-arrow:active {
      box-shadow: 0px 0px 2px #ffffff;
      background: #0000004f;
    }

    .disabled-arrow {
      height: 80%;
      width: 36px;
    }
  }
}

@media (max-width: 800px) {
  .modal-carousel {
    .close-button-holder {
      padding: 1rem 1.5rem 0 0;
    }

    .image-box {
      padding: 0 5px;
      margin-top: 0.5rem;

      img {
        max-height: 80%;
        max-width: calc(100% - 85px);
      }

      .active-arrow {
        height: 25%;
      }

      .active-arrow:hover {
        background: none;
      }

      .active-arrow:active {
        box-shadow: 0px 0px 2px #ffffff;
        background: #0000004f;
      }
    }
  }
}

@media (max-width: 320px) {
  .modal-carousel {
    .image-box {
      img {
        max-height: 75%;
        max-width: 75%;
      }
    }
  }
}
</style>
