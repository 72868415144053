const initialState = () => ({
  keep_filters: false,

  filters: {
    start_timestamp: null,
    end_timestamp: null,
    user: [],
    user_id: [],
    dateType: "last_message_at",
    has_chat: true,
    sales_stage_id: [],
  },

  date_types: [{ id: "last_message_at", title: "Mensagem mais recente" }],
});

const getters = {
  getFilters(state) {
    return state.filters;
  },

  getDateType(state) {
    return state.date_types;
  },

  getKeepFilters(state) {
    return state.keep_filters;
  },

  haveAnyActiveFilters(state) {
    if (state.filters.start_timestamp) {
      return true;
    }

    if (state.filters.end_timestamp) {
      return true;
    }

    if (state.filters.user_id.length) {
      return true;
    }

    if (!state.filters.has_chat) {
      return true;
    }

    return state.filters.sales_stage_id.length;
  },
};

const actions = {
  resetState({ state }) {
    const initial = initialState();

    const userFromUsers = state.redirectFromUsers;

    if (state.redirectFromUsers) {
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });

      state.filters.user_id[0] = userFromUsers.id;
      state.filters.user[0] = userFromUsers;
    } else if (!state.keep_filters) {
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }

    state.redirectFromUsers = null;
  },

  cleanValues({ state }) {
    if (!state.keep_filters) {
      state.filters = {
        start_timestamp: null,
        end_timestamp: null,
        user: [],
        user_id: [],
        has_chat: true,
        sales_stage_id: [],

        dateType: "last_message_at",
      };

      state.sortType = [];

      state.sortDirection = [];

      return state.filters;
    }
  },

  setFilters({ state }, filters) {
    state.filters = filters;
  },

  setIdFromUsers({ state }, userId) {
    state.redirectFromUsers = userId;
  },

  setKeepFilters({ state }, keep_filters) {
    state.keep_filters = keep_filters;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
};
