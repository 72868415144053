<template>
  <div class="opportunity-description">
    <h3 class="text-center pt-2 px-2 pb-2">Escreva uma observação</h3>

    <description-field
      @selected="setSelected()"
      @blur="handleBlur"
      :class="{ 'selected-box': isSelected }"
      :grow="true"
      class="mt-1"
      v-model="description"
    />

    <div v-show="isSelected" class="save-button">
      <b-button @click="save(true)" variant="light">Deletar</b-button>

      <b-button @click="save()" variant="primary">Salvar</b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

import DescriptionField from "@/views/forms/components/DescriptionField.vue";

export default {
  components: {
    BButton,

    DescriptionField,
  },

  data() {
    return {
      description: this.opportunity.description,
      hasSaveRequest: false,
      isSelected: false,

      savedMessage: "Descrição salva com sucesso!",
      deletedMessage: "Descrição deletada com sucesso!",
    };
  },

  props: {
    opportunity: {
      type: Object,
      required: true,
      default: null,
    },

    fromOmnichannelDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    setSelected() {
      this.isSelected = true;
    },

    handleBlur() {
      setTimeout(() => {
        if (!this.hasSaveRequest) {
          this.isSelected = false;
        }
      }, 100);
    },

    save(reset = false) {
      if (this.hasSaveRequest) {
        return;
      }

      this.hasSaveRequest = true;

      const updatedOpportunity = {
        id: this.opportunity.id,
        description: reset ? "" : this.description,
      };

      this.$store
        .dispatch("opportunityStore/save", updatedOpportunity)
        .then(() => {
          if (this.fromOmnichannelDesktop) {
            this.$store.commit("omnichannel/UPDATE");
          } else {
            this.$emit("saved", reset ? this.deletedMessage : this.savedMessage);
          }

          this.isSelected = false;
        })
        .finally(() => {
          this.hasSaveRequest = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.opportunity-description {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0px 10px 45px 10px;

  .selected-box {
    border: solid 1px #046af3;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .save-button {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 7px 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: solid 1px #046af3;
  }
}
</style>
