/* eslint-disable no-empty-pattern */
//import formbricks from "@formbricks/js/app";
import Vue from "vue";
import { instance } from "@/config/index.js";

const initialState = () => ({
  tags: [],

  source_types: [
    { id: "Ligacao", title: "Ligação" },
    { id: "Bella", title: "Bella" },
    { id: "WhatsappEscola", title: "Whatsapp Escola" },
    { id: "FormularioSite", title: "Formulário Site" },
    { id: "FormularioRedesSociais", title: "Formulário Redes Sociais" },
    { id: "VisitaEscola", title: "Visita Escola" },
    { id: "Messenger", title: "Messenger" },
    { id: "Inbox", title: "Inbox" },
    { id: "Email", title: "Email" },
    { id: "Direct", title: "Direct" },
    { id: "Comentario", title: "Comentário" },
    { id: "Importacao", title: "Importação" },
    { id: "Offline", title: "Offline" },
    { id: "URA", title: "URA" },
    { id: "Imbot", title: "IMBot" },
    { id: "DisparoDeMensagem", title: "Disparo de Mensagem" },
    { id: "FormularioExterno", title: "Formulário Externo" },
  ],

  seller_approach: [
    { id: "DigitalService", title: "Atendimento Digital" },
    { id: "RemoteSales", title: "Televendas" },
    { id: "SpontaneousVisit", title: "Visita Espontanea" },
    { id: "ExternalVisit", title: "Visita PAP" },
    { id: "StreetVisit", title: "Visita Street" },
    { id: "ScheduledVisit", title: "Agendamento de visita" },
  ],

  reasons_lost: [],

  states: [
    { title: "Acre", id: "AC" },
    { title: "Alagoas", id: "AL" },
    { title: "Amapá", id: "AP" },
    { title: "Amazonas", id: "AM" },
    { title: "Bahia", id: "BA" },
    { title: "Ceará", id: "CE" },
    { title: "Distrito Federal", id: "DF" },
    { title: "Espírito Santo", id: "ES" },
    { title: "Goiás", id: "GO" },
    { title: "Maranhão", id: "MA" },
    { title: "Mato Grosso", id: "MT" },
    { title: "Mato Grosso do Sul", id: "MS" },
    { title: "Minas Gerais", id: "MG" },
    { title: "Pará", id: "PA" },
    { title: "Paraíba", id: "PB" },
    { title: "Paraná", id: "PR" },
    { title: "Pernambuco", id: "PE" },
    { title: "Piauí", id: "PI" },
    { title: "Rio de Janeiro", id: "RJ" },
    { title: "Rio Grande do Norte", id: "RN" },
    { title: "Rio Grande do Sul", id: "RS" },
    { title: "Rondônia", id: "RO" },
    { title: "Roraima", id: "RR" },
    { title: "Santa Catarina", id: "SC" },
    { title: "São Paulo", id: "SP" },
    { title: "Sergipe", id: "SE" },
    { title: "Tocantins", id: "TO" },
  ],

  campaigns: [],

  medias: [],

  opportunity_types: [],

  dictionaryStageTypes: [
    { value: "Default", text: "Padrão" },
    { value: "Won", text: "Ganhos" },
    { value: "Lost", text: "Perdidos" },
    { value: "First", text: "Abertura" },
    { value: "InProgress", text: "Em Progresso" },
    { value: "Scheduled", text: "Agendamentos" },
    { value: "Negotiation", text: "Negociação" },
    { value: "Omitted", text: "Omitido" },
    { value: "PreSale", text: "Pré-venda" },
  ],
});

const getters = {
  getSourceTypes(state) {
    return state.source_types;
  },

  getSellerApprouch(state) {
    return state.seller_approach;
  },

  getLostReasons(state) {
    return state.reasons_lost;
  },

  getCampaigns(state) {
    return state.campaigns;
  },

  getMedias(state) {
    return state.medias;
  },

  getSourceStates(state) {
    return state.states;
  },

  getSalesStageTypes(state) {
    return state.dictionaryStageTypes;
  },

  getTags(state) {
    return state.tags;
  },

  getOpportunityTypes(state) {
    return state.opportunity_types;
  },

  resetState(state) {
    const inital = initialState();

    Object.keys(inital).forEach((key) => {
      state[key] = inital[key];
    });
  },
};

const mutations = {
  SET_LOGOUT(state) {
    const inital = initialState();

    Object.keys(inital).forEach((key) => {
      state[key] = inital[key];
    });
  },

  reset_state_OportunityStore(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  fetchTags({ state }) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/tags?limit=500`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => {
          state.tags = response.data;

          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  save({}, opportunity) {
    let workspaceId = localStorage.getItem("currentWorkspace");

    if (opportunity.workspace_id) {
      workspaceId = opportunity.workspace_id;
    }

    // const opportunityId = 1
    const postUrl = `/workspaces/${workspaceId}/opportunities`;

    if (opportunity.id) {
      // Update
      return new Promise((resolve, reject) => {
        instance()
          .put(`${postUrl}/${opportunity.id}`, opportunity)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    }

    // Insert
    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, opportunity)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  update({}, opportunity) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/opportunities/${opportunity.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(postUrl, opportunity)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updateStep({}, opportunity) {
    const localOpportunity = opportunity;
    localOpportunity.display_order = "top";

    const url = `/workspaces/${opportunity.workspace_id}/opportunities/${localOpportunity.id}/move-to`;

    return new Promise((resolve, reject) => {
      instance()
        .put(url, localOpportunity)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  assign({}, opportunity) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const localOpportunity = opportunity;
    localOpportunity.display_order = "top";

    const data = {
      user_id: localOpportunity.user_id,
    };

    // const opportunityId = 1
    const url = `/workspaces/${workspaceId}/opportunities/${localOpportunity.id}/assign`;

    return new Promise((resolve, reject) => {
      instance()
        .put(url, data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetch({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/opportunities${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchStages({}) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const getUrl = `/workspaces/${workspaceId}/sales-stages`;

    return new Promise((resolve, reject) => {
      instance()
        .get(getUrl)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchLostReasons({ state }) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/objections?limit=500`)
        .then((response) => {
          state.reasons_lost = response.data;

          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },

  async getStages({}, workspace_id) {
    const workspaceId = workspace_id ? workspace_id : localStorage.getItem("currentWorkspace");

    let salesStage = await new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/sales-stages`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });

    let stages = [];

    if (salesStage.length) {
      salesStage.forEach((saleStage, index) => {
        stages.push({
          id: saleStage.id,
          title: saleStage.name,
          color: saleStage.color,
          type: saleStage.type,
          value: 0,
          totalCount: 0,
          totalAmount: 0,
          step: {
            name: `Etapa ${index + 1}`,
            count: index + 1,
          },
          hasRequest: true,
          tasks: [],
        });
      });
    }

    return stages;
  },

  fetchCampaigns({ state }) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/campaigns?limit=500`)
        .then((response) => {
          state.campaigns = response.data;
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },

  fetchMedias({ state }, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/medias${params || "?limit=500"}`)
        .then((response) => {
          state.medias = response.data;
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },

  fetchOpTypes({ state }, params) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`opportunity-types${params || "?limit=500"}`)
        .then((response) => {
          state.opportunity_types = response.data;

          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },

  fetchHistory({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/opportunities/history${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  transferOpportunity({}, data) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/opportunities/${data.opportunity_id}/transfer`;

    const dataForTransfer = {
      workspace_id: data.workspace_id,
      user_id: data.user_id,
      sales_stage_id: data.sales_stage_id,
      keep_user_id: data.keep_user_id,
    };

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, dataForTransfer)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  massTransferOpportunity({}, data) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/opportunities/batch-transfer`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, data)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchOne({}, id) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/opportunities/${id}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchOpportunityForChat({}, id) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/opportunities/${id + "?show_old_chats=true"}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getUsers({}, fetchProprieties) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${fetchProprieties.workspaceId}/users${fetchProprieties.params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  close({}, opportunity) {
    const postUrl = `/workspaces/${opportunity.workspace_id}/opportunities/${opportunity.id}/close`;

    return new Promise((resolve, reject) => {
      instance()
        .put(postUrl, opportunity)
        .then((response) => {
          //formbricks.track("close_opportunity");

          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  closeOpportunity({}, opportunity) {
    const postUrl = `/workspaces/${opportunity.workspace_id}/opportunities/${opportunity.id}/close`;

    return new Promise((resolve, reject) => {
      instance()
        .put(postUrl, opportunity.data)
        .then((response) => {
          //formbricks.track("close_opportunity");

          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  move({}, opportunity) {
    const url = `/workspaces/${opportunity.workspace_id}/opportunities/${opportunity.id}/move-to`;

    return new Promise((resolve, reject) => {
      instance()
        .put(url, opportunity.data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  //Products
  fetchProducts({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/products${params || ""}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchProduct({}, id) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const url = `/workspaces/${workspaceId}/products/${id}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(url)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  importLeads({}, data) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/opportunities/upload`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
};
