<template>
  <div @dragover.prevent @dragenter.prevent="handleDragEnter($event)" class="opportunity-tabs">
    <close-opportunity-from-chat
      v-if="showCloseChat"
      @refresh="handleSaved()"
      @closeHalfModal="closeHalfModal()"
      :opportunity="opportunity"
      :fromOmnichannelDesktop="fromOmnichannelDesktop"
      :newestChat="newestChat"
    />

    <b-tabs
      v-if="!hasOpportunityRequest && opportunity"
      :id="fromOmnichannelDesktop ? 'icons-menu-omnichannel-desktop' : 'icons-menu'"
      v-model="activeTab"
    >
      <b-tab
        v-if="!fromOmnichannelDesktop"
        @click="verifyCancel($event)"
        id="icon"
        class="red-icon"
      >
        <template slot="title">
          <i style="cursor: pointer" class="icon-close" id="icon-red" />
        </template>
      </b-tab>

      <b-tab
        :active="fromOmnichannelDesktop || fromOmnichannelMobile || fromOmniRedirect"
        id="icon"
        class="modal-space green-icon"
        lazy
      >
        <template slot="title">
          <i class="icon-imbot" style="font-size: 25px" id="icon-green" />
        </template>

        <card-top-info-component
          v-if="!fromOmnichannelDesktop"
          @new-task="openNewTaskModal()"
          @singleVoipCall="singleVoipCall()"
          @closeByChat="closeByChat()"
          :opportunity="opportunity"
          :fromChat="newestChat ? true : false"
          :fromOmnichannelDesktop="fromOmnichannelDesktop"
          :newestChat="newestChat"
          class="chat-tpo-info"
        />

        <omnichat
          @selectOpFromOmnichat="$emit('selectOpFromOmnichat', $event)"
          @saved="handleSaved()"
          @closed="$emit('close')"
          @roomReopened="handleRoomOpened($event)"
          @opportunityRedirect="$emit('opportunityRedirect', $event)"
          :opportunity="opportunity"
          :fromOmnichannelDesktop="fromOmnichannelDesktop"
          :fromOmniRedirect="fromOmniRedirect"
          :key="chatKey"
        />

        <drop-file-box v-show="hasDraggable" />

        <input-chat-bar
          @roomReopened="handleRoomOpened($event)"
          @roomOpened="handleRoomOpened($event)"
          :opportunity="opportunity"
          :newestChat="newestChat"
        />
      </b-tab>

      <b-tab
        v-if="!fromOmnichannelDesktop"
        :active="!fromOmnichannelMobile && !fromOmniRedirect && !focusHistory"
        id="icon"
        class="modal-space blue-icon"
      >
        <template #title>
          <i class="icon-user-profile" id="icon-blue" />
        </template>

        <template #default>
          <update-opportunity
            @saved="handleSaved()"
            @transferred="handleTransferred()"
            @close="$emit('close')"
            :opportunity_prop="opportunity"
            :watchVerifyCancel="verifyFlag"
            :watchSave="watchSave"
            :isFromExternalCall="isFromExternalCall"
            :newestChat="newestChat"
          />
        </template>
      </b-tab>

      <b-tab id="icon" class="modal-space nice-Vscroll blue-icon">
        <template slot="title">
          <i class="icon-phone-chat-icon" id="icon-blue" />
        </template>

        <chat-phone :opportunity="opportunity" @call="openNewTaskModal()" />
      </b-tab>

      <b-tab :active="focusHistory" id="icon" class="modal-space nice-Vscroll blue-icon" lazy>
        <template slot="title">
          <i class="icon-task-clock" id="icon-blue" />
        </template>

        <contact-history
          v-if="opportunity.contact"
          @refresh="handleSaved()"
          :contact="opportunity.contact"
          id="contact-history"
        />
      </b-tab>

      <b-tab id="icon" class="modal-space nice-Vscroll blue-icon">
        <template slot="title">
          <i class="icon-file" id="icon-blue" />
        </template>

        <card-top-info-component
          v-if="!fromOmnichannelDesktop"
          @new-task="openNewTaskModal()"
          @closeByChat="closeByChat()"
          @singleVoipCall="singleVoipCall()"
          :opportunity="opportunity"
          :fromOmnichannelDesktop="fromOmnichannelDesktop"
          :newestChat="newestChat"
        />

        <opportunity-description
          @saved="handleSaved($event)"
          :opportunity="opportunity"
          :fromOmnichannelDesktop="fromOmnichannelDesktop"
        />
      </b-tab>
    </b-tabs>

    <div v-else class="spinner-holder">
      <b-spinner />
    </div>

    <b-modal
      v-if="opportunity"
      id="opportunity-tabs-task-form"
      class="modal-dialog"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <task-form
        @saved="handleTaskSaved()"
        @closed="$bvModal.hide('opportunity-tabs-task-form')"
        :opportunity="opportunity"
        :parentId="opportunity.id"
        createdFrom="Opportunity"
      />
    </b-modal>
  </div>
</template>

<script>
import { BSpinner, BTabs, BTab } from "bootstrap-vue";

import CardTopInfoComponent from "@/views/components/Omnichannel/CardTopInfoComponent";
import ChatPhone from "@/views/components/chat/ChatPhone";
import CloseOpportunityFromChat from "@/views/components/CloseOpportunityFromChat";
import ContactHistory from "@/views/ContactHistory";
import DropFileBox from "@/views/components/DropFileBox";
import InputChatBar from "@/views/components/Omnichannel/InputChatBar";
import Omnichat from "@/views/components/chat/Omnichat";
import OpportunityDescription from "@/views/forms/components/OpportunityDescription";
import UpdateOpportunity from "@/views/forms/UpdateOpportunity";
import TaskForm from "@/views/forms/TaskForm";

export default {
  components: {
    BSpinner,
    BTabs,
    BTab,

    CardTopInfoComponent,
    ChatPhone,
    CloseOpportunityFromChat,
    ContactHistory,
    DropFileBox,
    InputChatBar,
    Omnichat,
    OpportunityDescription,
    UpdateOpportunity,
    TaskForm,
  },

  props: {
    opportunity_id: {
      required: true,
      default: null,
    },

    fromOmnichannelDesktop: {
      type: Boolean,
      default: false,
    },

    fromOmnichannelMobile: {
      type: Boolean,
      default: false,
    },

    isChatFromOmniClosed: {
      type: Boolean || undefined,
      default: false,
    },

    fromOmniRedirect: {
      type: Boolean,
      default: false,
    },

    isFromExternalCall: {
      type: Boolean,
      default: false,
    },

    focusHistory: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      opportunity: null,
      newestChat: null,

      hasOpportunityRequest: false,

      showCloseChat: false,
      verifyFlag: false,
      watchSave: false,

      activeTab: 0,
      chatKey: 0,
    };
  },

  computed: {
    workspace() {
      return this.$store.getters["app/getUserWorkspace"];
    },

    hasDraggable() {
      return this.$store.getters["omnichannel/getIsHovering"];
    },

    user() {
      return this.$store.getters["app/getUserData"];
    },

    externalPhoneSingleCallFallbackTick() {
      return this.$store.getters["externalPhone/getSingleCallFallbackTick"];
    },
  },

  created() {
    this.fetchOpportunity();
  },

  watch: {
    externalPhoneSingleCallFallbackTick() {
      this.openNewTaskModal();
    },
  },

  methods: {
    openNewTaskModal() {
      this.$bvModal.show("opportunity-tabs-task-form");
    },

    closeByChat() {
      this.showCloseChat = true;
    },

    closeHalfModal() {
      this.showCloseChat = false;
    },

    verifyCancel() {
      if (this.activeTab == 2) {
        this.verifyFlag = !this.verifyFlag;

        this.$nextTick(() => {
          this.activeTab = 2;
        });

        return;
      }

      this.$emit("close");
    },

    getNewestChatInfo() {
      if (!this.opportunity.dialogs?.length) return;

      this.newestChat = this.opportunity.dialogs[0];
    },

    handleTransferred() {
      this.$emit("saved");

      this.$emit("close");
    },

    handleSaved(message = null) {
      this.$emit("saved");

      if (message) {
        this.$bvToast.toast(message, {
          title: "Sucesso!",
          autoHideDelay: 2500,
          variant: "success",
          toaster: "b-toaster-top-left",
          solid: true,
        });
      }

      this.fetchOpportunity(this.activeTab);
    },

    handleTaskSaved() {
      this.$bvToast.toast("Tarefa salva com sucesso", {
        title: `Tarefa salva!`,
        autoHideDelay: 2500,
        variant: "success",
        toaster: "b-toaster-top-left",
        solid: true,
      });

      this.$bvModal.hide("opportunity-tabs-task-form");
    },

    handleDragEnter(event) {
      if (this.fromOmnichannelDesktop) {
        if (this.activeTab == 0) {
          this.$store.dispatch("omnichannel/changeHoveringState", true);
        }

        return;
      }

      if (this.activeTab == 1) {
        this.$store.dispatch("omnichannel/changeHoveringState", true);
      }
    },

    handleRoomOpened(newDialog) {
      this.$emit("roomReopened", newDialog);

      if (!this.fromOmnichannelDesktop) {
        if (this.opportunity.dialogs) {
          if (this.opportunity?.dialogs[0]?.id != newDialog.id) {
            this.opportunity.dialogs.unshift(newDialog);
          } else {
            this.opportunity.dialogs[0] = newDialog;
          }

          this.getNewestChatInfo();

          this.chatKey++;

          return;
        }

        this.opportunity.dialogs = [newDialog];

        this.getNewestChatInfo();

        this.chatKey++;
      }
    },

    singleVoipCall() {
      this.$store
        .dispatch("externalPhone/getSipCredentials", this.user.id)
        .then((response) => {
          if (response.data.voip_username && response.data.voip_password) {
            this.$store.commit("externalPhone/CHANGE_USER_CREDENTIALS", {
              user_name: response.data.voip_username,
              password: response.data.voip_password,
            });

            this.$store.commit("externalPhone/CHANGE_SINGLE_OPPORTUNITY_ID", this.opportunity?.id);
            this.$store.commit("externalPhone/CHANGE_IS_SINGULAR_CALL", true);
            this.$store.commit("externalPhone/CHANGE_IS_DIALING", true);
            this.$store.commit("externalPhone/CHANGE_STATUS", "onHold");

            this.$store.commit("externalPhone/TRIGGER_START_CALLS");
          } else {
            this.$bvToast.toast("Usuário sem credenciais de VoIP neste workspace", {
              title: "Usuário sem acesso",
              variant: "warning",
              solid: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchOpportunity(activeTab = null) {
      this.hasOpportunityRequest = true;

      this.$store
        .dispatch("opportunityStore/fetchOne", this.opportunity_id)
        .then((response) => {
          this.opportunity = response.data;

          this.getNewestChatInfo();
        })
        .catch(() => {
          this.$emit("close");
        })
        .finally(() => {
          this.hasOpportunityRequest = false;

          this.$nextTick(() => {
            if (activeTab) {
              this.activeTab = activeTab;
            }
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.opportunity-tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100dvh;
  width: 100%;
  overflow: hidden;

  .tabs {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  :deep(#icons-menu__BV_tab_container_) {
    height: 100%;
    width: 100%;
  }

  :deep(#icons-menu__BV_tab_controls_),
  :deep(#icons-menu-omnichannel-desktop__BV_tab_controls_) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 55px;
    width: 100%;
    margin: 0;
    border-bottom: 1px solid #e5e5e5;
    border-radius: 0;
    background: #ffffff;

    #icon___BV_tab_button__ {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background: #f5f6f8;
      cursor: pointer;

      &.active {
        #icon-red {
          color: #ff2c55;
        }

        #icon-green {
          color: #2ed47a;
        }

        #icon-blue {
          color: #046af3;
        }
      }

      &:after {
        background: transparent !important;
      }

      i {
        color: black;
        margin-right: 0 !important;
        font-size: 18px;
      }
    }
  }

  :deep(#icons-menu__BV_tab_controls_) {
    .nav-item:nth-child(1) a.active {
      border: 1px solid #ff2c55;
    }
    .nav-item:nth-child(2) a.active {
      border: 1px solid #2ed47a;
    }
    .nav-item:nth-child(3) a.active {
      border: 1px solid #046af3;
    }
    .nav-item:nth-child(4) a.active {
      border: 1px solid #046af3;
    }
    .nav-item:nth-child(5) a.active {
      border: 1px solid #046af3;
    }
    .nav-item:nth-child(6) a.active {
      border: 1px solid #046af3;
    }
  }

  :deep(#icons-menu-omnichannel-desktop__BV_tab_controls_) {
    .nav-item:nth-child(1) a.active {
      border: 1px solid #2ed47a;
    }
    .nav-item:nth-child(2) a.active {
      border: 1px solid #046af3;
    }
    .nav-item:nth-child(3) a.active {
      border: 1px solid #046af3;
    }
    .nav-item:nth-child(4) a.active {
      border: 1px solid #046af3;
    }
  }

  .modal-space {
    display: flex;
    flex-direction: column;
    height: calc(100dvh - 55px);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .chat-tpo-info {
      padding: 5px 23px;
    }
  }

  .spinner-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}
</style>
