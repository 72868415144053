<template>
  <div class="ios-audio-player">
    <feather-icon
      @click="startPlayback()"
      :icon="isPlaying ? 'PauseIcon' : 'PlayIcon'"
      class="play-ico"
      size="20"
    />

    <div class="timer-holder">
      <div class="timer">{{ formatTime(currentTime) }}</div>

      <div>/</div>

      <div class="timer">{{ formatTime(duration) }}</div>
    </div>
  </div>
</template>

<script>
import { OGVPlayer, OGVLoader } from "ogv";
import { EventBus } from "@/eventBus";

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      player: null,

      isPlaying: false,

      timer: null,

      currentTime: 0,
      duration: 0,
    };
  },

  mounted() {
    this.playerSetup();

    EventBus.$on("stopAllAudios", () => {
      this.pausePlayback();
      this.resetAudio();
    });
  },

  beforeDestroy() {
    EventBus.$off("stopAllAudios", this.pausePlayback);
  },

  methods: {
    formatTime(seconds) {
      if (!seconds) return "0:00";

      const minutes = Math.floor(seconds / 60);

      const secs = Math.floor(seconds % 60)
        .toString()
        .padStart(2, "0");

      return `${minutes}:${secs}`;
    },

    startTimer() {
      if (this.timer) clearInterval(this.timer);

      this.timer = setInterval(() => {
        this.currentTime++;

        if (this.currentTime >= this.duration) {
          this.currentTime = this.duration;
        }
      }, 1000);
    },

    playerSetup() {
      OGVLoader.base = "ogv";

      this.player = new OGVPlayer();
      this.player.src = this.src;

      this.player.addEventListener("loadedmetadata", () => {
        this.duration = this.player.duration;
      });

      this.player.addEventListener("play", () => {
        this.startTimer();

        this.isPlaying = true;
      });

      this.player.addEventListener("pause", () => {
        clearInterval(this.timer);

        this.isPlaying = false;
      });

      this.player.addEventListener("ended", () => {
        clearInterval(this.timer);

        this.currentTime = 0;
        this.isPlaying = false;
      });
    },

    startPlayback() {
      if (this.isPlaying) {
        this.pausePlayback();
      } else {
        EventBus.$emit("stopAllAudios");

        this.playPlayback();
      }
    },

    playPlayback() {
      try {
        this.player.play();
      } catch (error) {
        console.log("Error initializing the OGVPlayer", error);
      }
    },

    pausePlayback() {
      try {
        this.player.pause();
      } catch (error) {
        console.log("Error pausing the OGVPlayer", error);
      }
    },

    resetAudio() {
      if (this.player) {
        this.player.currentTime = 0;
        this.currentTime = 0;
        this.isPlaying = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ios-audio-player {
  display: flex;
  align-items: center;
  position: relative;
  height: 54px;
  padding: 5px 2rem;
  gap: 10px;
  border-radius: 75px;
  color: #191919;
  background-color: #f1f3f4;

  .play-ico {
    cursor: pointer;
  }

  .timer-holder {
    display: flex;
    align-items: center;
    gap: 2px;

    .timer {
      width: 32px;
    }
  }
}
</style>
