import { render, staticRenderFns } from "./TaskForm.vue?vue&type=template&id=58043ca3&scoped=true"
import script from "./TaskForm.vue?vue&type=script&lang=js"
export * from "./TaskForm.vue?vue&type=script&lang=js"
import style0 from "./TaskForm.vue?vue&type=style&index=0&id=58043ca3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58043ca3",
  null
  
)

export default component.exports