import { instance } from "@/config/index.js";

const initialState = () => ({
  currentNotification: null,
  updateToken: false,
  isShowingNotification: false,

  hasNotificationBullet: false,

  unreadOmniNotifications: [],

  remoteConfig: {},
});

const getters = {
  getCurrentNotification: (state) => state.currentNotification,

  getUpdateToken: (state) => state.updateToken,

  getIsShowingNotification: (state) => state.isShowingNotification,

  getHasNotificationBullet: (state) => state.hasNotificationBullet,

  getUnreadOmniNotifications: (state) => state.unreadOmniNotifications,

  getRemoteConfig: (state) => state.remoteConfig,
};

const actions = {
  registerToken({}, data) {
    return new Promise((resolve, reject) => {
      instance()
        .post(`fcm/create-token`, data)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
};

const mutations = {
  setCurrentNotification(state, notification) {
    state.currentNotification = notification;
  },

  setUpdateToken(state) {
    state.updateToken = !state.updateToken;
  },

  setIsShowingNotification(state, value) {
    state.isShowingNotification = value;
  },

  setHasNotificationBullet(state, value) {
    state.hasNotificationBullet = value;
  },

  clearCurrentNotification(state) {
    state.currentNotification = null;
  },

  addUnreadOmniNotifications(state, notification) {
    state.unreadOmniNotifications.push(notification);
  },

  removeSpecificUnreadOmniNotification(state, notificationId) {
    state.unreadOmniNotifications = state.unreadOmniNotifications.filter(
      (item) => item != notificationId
    );
  },

  setUnreadOmniNotifications(state, notifications) {
    state.unreadOmniNotifications = notifications;
  },

  setRemoteConfig(state, config) {
    state.remoteConfig = config;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};
