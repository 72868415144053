import { render, staticRenderFns } from "./OpportunityTabs.vue?vue&type=template&id=62913084&scoped=true"
import script from "./OpportunityTabs.vue?vue&type=script&lang=js"
export * from "./OpportunityTabs.vue?vue&type=script&lang=js"
import style0 from "./OpportunityTabs.vue?vue&type=style&index=0&id=62913084&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62913084",
  null
  
)

export default component.exports