<template>
  <div :class="getCloseClass()" class="close-modal-general">
    <div @click="closeHalfModal" class="backdrop" />

    <div class="half-modal-confirmation">
      <div class="absolute-check">
        <i class="icon-check" />
      </div>

      <div style="padding: 5px 20px">
        <div class="warning-box">
          <div style="font-size: 20px; font-weight: 500; color: black">Aviso!</div>

          <div>
            Ao encerrar a conversa, a troca de mensagens entre você e a oportunidade não será mais
            possível até que você reabra esta sala.
          </div>

          <b style="color: black">Tem certeza que deseja fechar esta sala? </b>
        </div>

        <div :class="getConfirmationButtonClass()">
          <b-button @click="closeHalfModal()" variant="light"> Cancelar </b-button>

          <b-button @click="closeChat" variant="primary">
            <div v-if="!hasCloseRoomRequest">Confirmar</div>

            <b-spinner v-else />
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/views/components/Avatar.vue";
import { BButton, BSpinner } from "bootstrap-vue";

export default {
  components: {
    Avatar,
    BButton,
    BSpinner,
  },

  props: {
    opportunity: {
      type: Object,
      required: true,
      default: null,
    },

    fromOpportunityDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },

    fromOmnichannelDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },

    newestChat: {
      default: function () {
        return {};
      },
    },
  },

  data() {
    return {
      hasCloseRoomRequest: false,
    };
  },

  computed: {
    platform_terms() {
      const platformData = this.$store.getters["whiteLabel/getPlatform"];

      return platformData.terms;
    },

    user() {
      return this.$store.getters["app/getUserData"];
    },
  },

  methods: {
    getCloseClass() {
      if (!this.fromOmnichannelDesktop && !this.fromOpportunityDesktop) {
        return "close-modal-general-mobile";
      }

      if (this.fromOmnichannelDesktop) {
        return "close-modal-general-omnichannel-desktop";
      }

      if (this.fromOpportunityDesktop) {
        return "close-modal-general-desktop";
      }
    },

    getConfirmationButtonClass() {
      if (!this.fromOmnichannelDesktop && !this.fromOpportunityDesktop) {
        return "confirmation-buttons";
      }

      if (this.fromOmnichannelDesktop) {
        return "confirmation-buttons-omnichannel-desktop";
      }

      if (this.fromOpportunityDesktop) {
        return "confirmation-buttons-desktop";
      }
    },

    closeHalfModal() {
      if (this.hasCloseRoomRequest) return;

      this.$emit("closeHalfModal");
    },

    closeChat() {
      if (this.hasCloseRoomRequest) return;

      this.hasCloseRoomRequest = true;

      this.$store
        .dispatch("omnichannel/closeChat", this.newestChat?.id)
        .then(() => {
          this.$bvToast.toast("Sala do IMBOT fechada com sucesso!", {
            title: `Sala fechada!`,
            autoHideDelay: 1500,
            variant: "success",
            toaster: "b-toaster-top-left",
            solid: true,
          });

          setTimeout(() => {
            this.hasCloseRoomRequest = false;

            this.$emit("refresh");
            this.$emit("closeHalfModal");
          }, 1500);
        })
        .catch(() => {
          this.hasCloseRoomRequest = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.close-modal-general-mobile {
  position: absolute;
}

.close-modal-general-omnichannel-desktop {
  position: fixed;
  max-width: calc(33vw - 30px);
  bottom: 0;
}

.close-modal-general-desktop {
  position: fixed;
  max-width: 500px;
  bottom: 0;
}

.close-modal-general {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  z-index: 10000;
  background: #00000027;
  overflow: hidden;

  .backdrop {
    height: 100%;
    width: 100%;
  }

  .half-modal-confirmation {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 285px;
    min-height: 285px;
    padding: 45px 0 64px 0;
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    background: white;

    .absolute-check {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      font-size: 22px;
      height: 78px;
      width: 78px;
      top: -39px;
      left: calc(50% - 39px);
      border-radius: 22px;
      background: #c4e2ff;
      color: #046af3;
    }

    .warning-box {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;
      padding: 15px;
      gap: 10px;
      border-radius: 6px;
      background: #f5f6f8;
    }
  }

  .confirmation-buttons,
  .confirmation-buttons-omnichannel-desktop,
  .confirmation-buttons-desktop {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    height: 64px;
    width: 100%;
    bottom: 0;
    border-top: solid 1px #f5f6f8;
  }

  .confirmation-buttons {
    left: 0;
    max-width: 500px;

    @media (max-width: 800px) {
      max-width: none;
    }
  }

  .confirmation-buttons-omnichannel-desktop {
    max-width: calc(33vw - 30px);
    right: 0;
  }

  .confirmation-buttons-desktop {
    max-width: 500px;
    left: 0;
  }
}
</style>
