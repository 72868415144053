// firebaseService.js
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { getRemoteConfig, getAll, fetchAndActivate } from "firebase/remote-config";

export function initializeFirebase(store) {
  const firebaseConfig = {
    apiKey: process.env.VUE_APP_FB_API_KEY,
    authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FB_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FB_APP_ID,
    measurementId: process.env.VUE_APP_FB_MEASUREMENT_ID,
  };

  const app = initializeApp(firebaseConfig);

  const remoteConfig = getRemoteConfig(app);

  let rcDefaults = require("@/assets/Firebase/remote_config_defaults.json");

  remoteConfig.settings.minimumFetchIntervalMillis = 60000;
  remoteConfig.defaultConfig = rcDefaults;

  fetchAndActivate(remoteConfig)
    .then(() => {
      store.commit("firebase/setRemoteConfig", getAll(remoteConfig));
    })
    .catch((err) => {
      console.log(err);
    });

  return getMessaging(app);
}
